
.storeDecorate-container {
    display: flex;
    flex-direction: column;
    background: #fff;
    min-height: calc(100vh - 120px);
    box-sizing: border-box;
}
.computer-banner {
    width: 100%;
    /*height: 436px;*/
    height: 395px;
    .banner-img {
        display: block;
        /*width: calc(100% - 180px);*/
        /*max-height: 630px;*/
        max-width: 100%;
        max-height: 100%;
        margin: 29px auto 0;
    }
    ::v-deep .swiper-wrapper {
        height: calc(100% - 20px) !important;
        .swiper-slide {
            display: flex;
            justify-content: center;
            align-items: center;
            height: calc(100% - 20px) !important;
        }
    }
    ::v-deep .swiper-pagination {
        position: relative;
        bottom: 0;
    }
    ::v-deep .swiper-pagination-bullets {
        .swiper-pagination-bullet {
            width: 30px;
            height: 3px;
            background: #2C405F;
            border-radius: 0;
        }
    }
    .no-img {
        width: 264px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .banner-swiper-btn-prev,
    .banner-swiper-btn-next {
        width: 90px;
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
        opacity: .5;
        cursor: pointer;
        img {
            display: block;
            width: 20px;
        }
        &:hover {
            opacity: 1;
        }
    }
    .banner-swiper-btn-prev {
        left: 0;
        i {
            color: #BFBFBF;
            font-size: 30px;
        }
    }
    .banner-swiper-btn-next {
        right: 0;
        i {
            color: #BFBFBF;
            font-size: 34px;
        }
    }
}
.banner-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 90px 22px;
    .banner-item {
        width: calc(20% - 20px);
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
    }
    .upload_banner {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 82px;
        background-color: #FAFAFA;
        i{
            position: absolute;
            right: -5px;
            top: -5px;
            color: #d81e06;
            font-size: 12px;
            cursor: pointer;
        }
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    .upload-demo {
        width: 100%;
        ::v-deep .el-upload {
            width: 100%;
        }
    }
}
.storeSign-prompt {
    color: #999;
    margin: 0 90px 0;
    min-height: 45px;
    border: 1px solid #DCDCDC;
    line-height: 21px;
    padding: 10px 20px;
    box-sizing: border-box;
    .iconfont {
        color: #FF0000;
    }
    .title {
        color: #333;
        margin: 0 20px;
    }
}
.storeSign-btn {
    text-align: center;
    margin: 30px 0;
}
